@import url('https://fonts.googleapis.com/css?family=Raleway:300,400,500,700&display=swap');
:root{
  --sidebar-flex:1.5;
  --condiv-flex:6;
  --fontsize-p:1.2rem;
  --fontsize-nav:1.6rem;
  --typing-font:3rem;
}
*
{
  margin:0;
  padding: 0;
}
body{
  font-family: 'Raleway';
}
.App
{
  display: flex;
  width: 100vw;
  min-height: 100vh;
  background-color: #f9f9f9;
}
nav
{
  padding:60px 30px 0px 30px ;
  flex:var(--sidebar-flex);
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  /* align-items: center; */
  /* align-content: center; */
  /* align-self: center; */
  /* border-width: 20px; */
  /* background: rgb(238,103,143); */

  /* background: linear-gradient(180deg, rgba(238,103,143,1) 50%, rgba(132,124,252,1) 80%); */
}

h3.test
{
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  color: #2C3531;
  width: 8em
}
.condiv
{
  padding: 100px ;
  background-color: #f9f9f9;
  flex:var(--condiv-flex);
  border-left:1px solid #e8e8e8;
}

.home
{
  display: flex;
  /* justify-content: center; */
  flex-direction: column;
  /* align-items: center; */
  
}
.profilepic
{
  border-radius: 50%;
  width: 200px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  /* margin: 30px; */
}

.widePic
{
  width: 100%;

  /* margin: 30px; */
}

.typingeffect
{
  font-size: var(--typing-font);
  margin-bottom: 30px;
}

nav ul{
  font-size:var(--fontsize-nav) ;
  text-align: left;
  list-style-type: none;
  text-decoration: none;
  color: black !important;
}
nav ul li
{
  margin:40px;
}

a
{
  text-decoration: none;
  color: blue;
}

.subtopic
{
  margin:10px 10px 30px 10px !important;
  color: #2C3531;
}
p{
  font-size: var(--fontsize-p);
}
h1,h2,h3,h4,p{
  margin: 10px;
}
.social
{
  margin-top: -10px;
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  width: fit-content;
  /* margin-top: 20px; */
  display: flex;
  bottom: 60px;
}
.social i
{
  padding-right: 10px;
  font-size: 25px;
  color: #9a9b9c;
}
.social i:hover
{
  color: black;
}
.widecard
{
  border:0px solid #9a9b9c;
  display: flex;
  margin:30px 0px 30px 0px;
}
.widecard:hover
{
  color: #1b1d20;
  background-color: #ffffff;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}
.active
{
  font-weight: bolder;
}
.skills ul li
{
  list-style-type:none;
  margin:30px;
}

@media all and (max-width: 450px) {
  :root {
    --fontsize-nav:1rem;
    --sidebar-flex:0;
    --typing-font:2rem;
  }
  .App
  {
    flex-direction: column;
  }
  .sidebar
  {
    padding: 30px;
    background: rgb(238,103,143);
    background: linear-gradient(90deg, rgba(238,103,143,1) 50%, rgba(132,124,252,1) 80%);
  }
  .condiv
  {
    padding: 50px ;
  }
  nav ul
  {
    display: flex;
  }
  nav ul li
  {
    margin: 10px;
  }
  .social
  {
    left:0;
    width: 100vw;
    display: flex;
    justify-content: space-around;
    bottom: 30px;
  }
  .social i
  {
    padding-right: 0px;
  }
}